// What browser is being used?
navigator.sayswho = (() => {
  let ua = navigator.userAgent,
    tem,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return 'IE ' + (tem[1] || '')
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera')
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
  return M.join(' ')
})()

if (!Array.from) {
  Array.from = function (object) {
    return [].slice.call(object)
  }
}

let getMethods = obj => {
  var res = []
  for(var m in obj) {
    if(typeof obj[m] == "function") {
      res.push(m)
    }
  }
  return res
}

let intellectualPropertySymbols = () => {
  let elements = document.querySelectorAll("h1,h2,h3,h4,h5,h6,p,a,span,li")
  Array.prototype.forEach.call(elements, function(el, i) {
    let html = el.innerHTML
    let re = new RegExp(/&reg;|®|&trade;|™|&copy;|©/gi)
    if (re.test(html)) {
      el.innerHTML = html
        .replace(/&reg;/gi, '<sup>&reg;</sup>')
        .replace(/®/gi, '<sup>&reg;</sup>')
        .replace(/&trade;/gi, '<sup>&trade;</sup>')
        .replace(/™/gi, '<sup>&trade;</sup>')
        .replace(/&copy;/gi, '<sup>&copy;</sup>')
        .replace(/©/gi, '<sup>&copy;</sup>')
        .replace(/(<sup>)+/gi, '<sup>')
        .replace(/(<\/sup>)+/gi, '</sup>')
    }
  })
}

let isValidEmail = email => {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

let toggleClass = (className, el) => {
  if (el) {
    if (el.classList) {
      el.classList.toggle(className)
    } else {
      let classes = el.className.split(' ')
      let existingIndex = classes.indexOf(className)

      if (existingIndex >= 0)
        classes.splice(existingIndex, 1)
      else
        classes.push(className)

      el.className = classes.join(' ')
    }
  }
}

let addClass = (className, el) => {
  if (el) {
    if (el.classList)
      el.classList.add(className)
    else
      el.className += ' ' + className
  }
}

let removeClass = (className, el) => {
  if (el) {
    if (el.classList)
      el.classList.remove(className)
    else
      el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
  }
}

let hasClass = (className, el) => {
  if (el) {
    if (el.classList)
      return el.classList.contains(className)
    else
      return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className)
  }
}

let matchHeight = selector => {
  let heights = {}
  let elements = document.querySelectorAll(selector)
  Object.keys(elements).forEach( k => {
    let el = elements[k]
    let top = el.getBoundingClientRect().top
    el.style.height = ''
    if (heights[top] === undefined || el.offsetHeight > heights[top]) {
      heights[top] = el.offsetHeight
    }
  })
  Object.keys(elements).forEach( k => {
    let el = elements[k]
    let top = el.getBoundingClientRect().top
    el.style.height = heights[top] + 'px'
  })
}

let throttle = (delay, callback) => {
  let previousCall = new Date().getTime()
  return function() {
    let time = new Date().getTime()
    if ((time - previousCall) >= delay) {
      previousCall = time
      callback.apply(null, arguments)
    }
  }
}

let debounce = (delay, callback) => {
  let timeout = null
  return function() {
    if (timeout) {
      clearTimeout(timeout)
    }
    let args = arguments
    timeout = setTimeout(() => {
      callback.apply(null, args)
      timeout = null
    }, delay)
  }
}

let scrollToEl = (element, duration, offset = 0) => {
  if (duration <= 0) return
  let scrollY = (window.scrollY) ? window.scrollY : document.documentElement.scrollTop
  let scrollToY = scrollY + element.getBoundingClientRect().top + offset
  let difference = scrollToY - scrollY
  let perTick = difference / duration * 10

  setTimeout(() => {
    window.scrollBy(0, perTick)
    if (scrollY === scrollToY) return
    scrollToEl(element, duration - 10, offset)
  }, 10)
}

let get = (url, onload, onerror = () => {}) => {
  var request = new XMLHttpRequest()
  request.open('GET', url, true)
  request.onload = onload
  request.onerror = onerror
  request.send()
}

let matches = (el, selector) => {
  return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector)
}

let closest = (query, el) => {
  if (el) {
    if (matches(el.parentNode, query)) {
      return el.parentNode
    } else {
      return closest(query, el.parentNode)
    }
  }
}

let ready = fn => {
  if (document.readyState != 'loading') {
    fn()
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

let initializeTray = () => {
  let tray_el = document.querySelector('#tray')
  let tray_links = document.querySelectorAll('[href^="#tray-"]')
  let tray_blocks = tray_el.querySelectorAll('.tray__wrapper > div')
  Object.keys(tray_links).forEach(k => {
      let tray_link = tray_links[k]
      tray_link.addEventListener('click', e => {
        e.preventDefault()
        e.target.blur()
        let target = tray_link.getAttribute('href')
        let tray_block = tray_el.querySelector(target)
        if (hasClass('active', tray_link)) {
            removeClass('show', tray_el)
            removeClass('active', tray_link)
            Object.keys(tray_blocks).forEach(i => {
                removeClass('show', tray_blocks[i])
            })
        } else {
            Object.keys(tray_links).forEach(i => {
                removeClass('active', tray_links[i])
            })
            Object.keys(tray_blocks).forEach(i => {
                removeClass('show', tray_blocks[i])
            })
            addClass('show', tray_el)
            addClass('active', tray_link)
            addClass('show', tray_block)
        }
      })
  })
  tray_el.addEventListener('click', e => {
    if (e.target == tray_el) {
      toggleClass('show', tray_el)
    }
  })
}

let trayScroll = () => {
  let scrollY = (window.scrollY) ? window.scrollY : document.documentElement.scrollTop
  let headerVisible = scrollY < document.querySelector('header').offsetHeight
  let tray = document.querySelector('#tray')
  if (headerVisible) {
    if (hasClass('visible', tray)) {
      removeClass('visible', tray)
    }
  } else {
    if (!hasClass('visible', tray)) {
      addClass('visible', tray)
    }
  }
}

let initializeHeaderNav = () => {
  let header = document.querySelector('.header__wrapper')
  let stickyHeader = header.cloneNode(true)
  stickyHeader.removeChild(stickyHeader.querySelector('.nav--utility'))
  stickyHeader.removeChild(stickyHeader.querySelector('#search-tray'))
  addClass('header__wrapper--sticky', stickyHeader)
  header.insertAdjacentHTML('afterend', stickyHeader.outerHTML)
}

let headerNavScroll = (e) => {
  let scrollY = (window.scrollY) ? window.scrollY : document.documentElement.scrollTop
  let header = document.querySelector('.header__wrapper:not(.header__wrapper--sticky)')
  let stickyHeader = document.querySelector('.header__wrapper--sticky')
  let menuToggle = document.querySelector('.menu-toggle')
  let mainNav = header.querySelector('nav:not(.nav--utility)')
  if ((scrollY < window.prevHeaderScrollY && header.getBoundingClientRect().top <= -header.offsetHeight) || hasClass('show', mainNav)) {
    addClass('header__wrapper--visible', stickyHeader)
  } else if ((scrollY > window.prevHeaderScrollY && !hasClass('close', menuToggle)) || header.getBoundingClientRect().top >= -header.offsetHeight) {
    removeClass('header__wrapper--visible', stickyHeader)
  }
  window.prevHeaderScrollY = scrollY
}

let initializeMobileNav = () => {
  let menuToggles = document.querySelectorAll('.menu-toggle')
  let navs = document.querySelectorAll('header nav:not(.nav--utility)')
  Object.keys(menuToggles).forEach(k => {
    let menuToggle = menuToggles[k]
    menuToggle.addEventListener('click', e => {
      if (hasClass('close', menuToggle)) {
        Object.keys(navs).forEach(n => {
          let nav = navs[n]
          removeClass('show', nav)
        })
        Object.keys(menuToggles).forEach(t => {
          let mT = menuToggles[t]
          removeClass('close', mT)
        })
      } else {
        removeClass('visible', document.querySelector('#tray'))
        Object.keys(navs).forEach(n => {
          let nav = navs[n]
          addClass('show', nav)
        })
        Object.keys(menuToggles).forEach(t => {
          let mT = menuToggles[t]
          addClass('close', mT)
        })
      }
    })
  })
}

let initializeSectionNav = () => {
  let sectionNav = document.querySelector('.section-nav')
  let stickySectionNav = sectionNav.cloneNode(true)
  addClass('section-nav--sticky', stickySectionNav)
  stickySectionNav.style.display= 'none'
  document.querySelector('.header__wrapper--sticky').insertAdjacentHTML('afterend', stickySectionNav.outerHTML)

  let sectionNavLinks = document.querySelectorAll('.section-nav a[href^="#"]')
  Object.keys(sectionNavLinks).forEach(k => {
    let link = sectionNavLinks[k]
    link.addEventListener('click', e => {
      e.preventDefault()
      let href = e.srcElement.getAttribute('href')
      let element = document.querySelector(href)
      let offset = stickySectionNav.offsetHeight
      if (element.getBoundingClientRect().top < 0) {
        offset += document.querySelector('.header__wrapper--sticky').offsetHeight
      }
      scrollToEl(element, 250, -offset)
    })
  })
}

let sectionNavScroll = () => {
  let sectionNav = document.querySelector('.section-nav:not(.section-nav--sticky)')
  let stickySectionNav = document.querySelector('.section-nav--sticky')
  let stickyHeader = document.querySelector('.header__wrapper--sticky')
  if (
    (sectionNav.getBoundingClientRect().top <= 0 && !hasClass('header__wrapper--visible', stickyHeader))
    ||
    (sectionNav.getBoundingClientRect().top <= stickyHeader.offsetHeight && hasClass('header__wrapper--visible', stickyHeader))
  ) {
    stickySectionNav.style.display = ''
  } else {
    stickySectionNav.style.display = 'none'
  }
}

let initializeSearchLinks = () => {
  let searchLinks = document.querySelectorAll('[href^="#search"]')
  Object.keys(searchLinks).forEach(k => {
    searchLinks[k].addEventListener('click', e => {
      e.preventDefault()
      let tray_el = document.querySelector('#search-tray')
      toggleClass('show', tray_el)
      document.querySelector('#search-tray .search-tray__input input').focus()
    })
  })
  let searchClose = document.querySelector('#search-tray [href="#close"]')
  let searchTray = document.querySelector('#search-tray')
  let searchTrayForm = document.querySelector('#search-tray form')
  let searchTrayWrapper = document.querySelector('#search-tray .search-tray__wrapper')
  let searchTrayInput = document.querySelector('#search-tray .search-tray__input')
  searchTray.addEventListener('click', e => {
    if (e.target === searchTray || e.target === searchTrayWrapper || e.target === searchClose || e.target === searchTrayInput || e.target === searchTrayForm) {
      removeClass('show', searchTray)
      searchClose.style.right = ''
      searchClose.style.left = ''
      searchClose.style.top = ''
    }
  })
  searchTray.addEventListener('mousemove', e => {
    if (e.target === searchTray || e.target === searchTrayWrapper || e.target === searchClose || e.target === searchTrayInput || e.target === searchTrayForm) {
      searchClose.style.right = 'auto'
      searchClose.style.left = (e.x - 15) + 'px'
      searchClose.style.top = (e.y - 15) + 'px'
      searchClose.style.opacity = 1
    } else {
      searchClose.style.opacity = 0
    }
  })
}

let initializeSliders = () => {
  loadjs([
    '/assets/scripts/lory.js'
  ], {
    success: () => {
      let sliderEl = document.querySelector('.slider')
      let slider = lory(sliderEl, {
        classNameFrame: 'slider__frame',
        classNameSlideContainer: 'slider__slides',
        infinite: 1,
        enableMouseEvents: true,
      })
      setInterval(slider.next, 6000)
    }
  })
}

let initializeComplexSliders = () => {
  loadjs([
    '/assets/scripts/lory.js'
  ], {
    success: () => {
      let sliderEl = document.querySelector('.slider-complex')
      let dot_count = sliderEl.querySelectorAll('.slider__image').length
      let prev_btn = sliderEl.querySelector('.slider__prev')
      let next_btn = sliderEl.querySelector('.slider__next')
      let dot_container = sliderEl.querySelector('.slider__dots')
      let dot_list_item = document.createElement('li')
      if (dot_count > 1) {
        addClass('slider__dot', dot_list_item)
        function handleDotEvent(e) {
          if (e.type === 'before.lory.init') {
            for (let i = 0, len = dot_count; i < len; i++) {
              let clone = dot_list_item.cloneNode()
              dot_container.appendChild(clone)
            }
            dot_container.childNodes[0].classList.add('active')
          }
          if (e.type === 'after.lory.init') {
            for (let i = 0, len = dot_count; i < len; i++) {
              dot_container.childNodes[i].addEventListener('click', function(e) {
                slider.slideTo(Array.prototype.indexOf.call(dot_container.childNodes, e.target))
              })
            }
            dot_container.setAttribute('text', '01 / ' + ('0'+dot_count).slice(-2))
          }
          if (e.type === 'after.lory.slide') {
            for (let i = 0, len = dot_container.childNodes.length; i < len; i++) {
              dot_container.childNodes[i].classList.remove('active')
            }
            dot_container.childNodes[e.detail.currentSlide - 1].classList.add('active')
            dot_container.setAttribute('text', ('0'+e.detail.currentSlide).slice(-2) + ' / ' + ('0'+dot_count).slice(-2))
          }
          if (e.type === 'on.lory.resize') {
            for (let i = 0, len = dot_container.childNodes.length; i < len; i++) {
              dot_container.childNodes[i].classList.remove('active')
            }
            dot_container.childNodes[0].classList.add('active')
          }
        }
        sliderEl.addEventListener('before.lory.init', handleDotEvent)
        sliderEl.addEventListener('after.lory.init', handleDotEvent)
        sliderEl.addEventListener('after.lory.slide', handleDotEvent)
        sliderEl.addEventListener('on.lory.resize', handleDotEvent)

        let slider = lory(sliderEl, {
          classNameFrame: 'slider__frame',
          classNameSlideContainer: 'slider__slides',
          infinite: 1,
          classNamePrevCtrl: 'section__prev',
          classNameNextCtrl: 'section__next',
          enableMouseEvents: true,
        })
        setInterval(slider.next, 6000)
      } else {
        sliderEl.querySelector('.slider__controls').style.display = 'none'
      }
    }
  })
}

let initializeCopySliders = () => {
  loadjs([
    '/assets/scripts/lory.js'
  ], {
    success: () => {
      let sliderEl = document.querySelector('.copy__slider')
      let prev_btn = sliderEl.querySelector('.slider__prev')
      let next_btn = sliderEl.querySelector('.slider__next')
      let dot_count = sliderEl.querySelectorAll('figure').length
      let dot_container = sliderEl.querySelector('.copy__slider__dots')
      let dot_list_item = document.createElement('li')
      addClass('slider__dot', dot_list_item)
      function handleDotEvent(e) {
        if (e.type === 'before.lory.init') {
          for (let i = 0, len = dot_count; i < len; i++) {
            let clone = dot_list_item.cloneNode()
            dot_container.appendChild(clone)
          }
          dot_container.childNodes[0].classList.add('active')
        }
        if (e.type === 'after.lory.init') {
          for (let i = 0, len = dot_count; i < len; i++) {
            dot_container.childNodes[i].addEventListener('click', function(e) {
              slider.slideTo(Array.prototype.indexOf.call(dot_container.childNodes, e.target))
            })
          }
          dot_container.setAttribute('text', '01 / ' + ('0'+dot_count).slice(-2))
        }
        if (e.type === 'after.lory.slide') {
          for (let i = 0, len = dot_container.childNodes.length; i < len; i++) {
            dot_container.childNodes[i].classList.remove('active')
          }
          dot_container.childNodes[e.detail.currentSlide - 1].classList.add('active')
          dot_container.setAttribute('text', ('0'+e.detail.currentSlide).slice(-2) + ' / ' + ('0'+dot_count).slice(-2))
        }
        if (e.type === 'on.lory.resize') {
          for (let i = 0, len = dot_container.childNodes.length; i < len; i++) {
            dot_container.childNodes[i].classList.remove('active')
          }
          dot_container.childNodes[0].classList.add('active')
        }
      }
      sliderEl.addEventListener('before.lory.init', handleDotEvent)
      sliderEl.addEventListener('after.lory.init', handleDotEvent)
      sliderEl.addEventListener('after.lory.slide', handleDotEvent)
      sliderEl.addEventListener('on.lory.resize', handleDotEvent)
      let slider = lory(sliderEl, {
        classNameFrame: 'copy__slider__frame',
        classNameSlideContainer: 'copy__slider__slides',
        infinite: 1,
        enableMouseEvents: true,
      })
      prev_btn.addEventListener('click', () => {
          slider.prev()
      })
      next_btn.addEventListener('click', () => {
          slider.next()
      })
    }
  })
}

let initializeSectionProjects = () => {
  loadjs([
    '/assets/scripts/lory.js'
  ], {
    success: () => {
      let sectionSliderEls = document.querySelectorAll('.section__slider')
      Object.keys(sectionSliderEls).forEach(k => {
        let sectionSliderEl = sectionSliderEls[k]
        let sliderContainer = sectionSliderEl.querySelector('.section__projects')
        let projectWrapperEls = sectionSliderEl.querySelectorAll('.project__wrapper')

        let dot_count = sectionSliderEl.querySelectorAll('.section__project').length
        let dot_container = sectionSliderEl.querySelector('.slider__nav')
        let handleProjectNavEvent = e => {
          if (e.type === 'before.lory.init') {
            dot_container.childNodes[0].classList.add('active')
          }
          if (e.type === 'after.lory.init') {
            for (let i = 0, len = dot_count; i < len; i++) {
              dot_container.childNodes[i].addEventListener('click', function(e) {
                slider.slideTo(Array.prototype.indexOf.call(dot_container.childNodes, e.target))
              })
            }
            dot_container.setAttribute('text', '01 / ' + ('0'+dot_count).slice(-2))

            for (let i = 0, len = dot_count; i < len; i++) {
              projectWrapperEls[i].addEventListener('click', function(e) {
                slider.slideTo(Array.prototype.indexOf.call(sliderContainer.childNodes, projectWrapperEls[i]))
              })
            }
          }
          if (e.type === 'after.lory.slide') {
            for (let i = 0, len = dot_container.childNodes.length; i < len; i++) {
              dot_container.childNodes[i].classList.remove('active')
            }
            dot_container.childNodes[e.detail.currentSlide].classList.add('active')

            let prevButton = sectionSliderEl.querySelector('.section__prev')
            let nextButton = sectionSliderEl.querySelector('.section__next')
            if (e.detail.currentSlide === 0) {
              prevButton.style.opacity = .25
              nextButton.style.opacity = ''
            } else if (e.detail.currentSlide === dot_container.childNodes.length-1) {
              prevButton.style.opacity = ''
              nextButton.style.opacity = .25
            } else {
              prevButton.style.opacity = ''
              nextButton.style.opacity = ''
            }
          }
          if (e.type === 'on.lory.resize') {
            for (let i = 0, len = dot_container.childNodes.length; i < len; i++) {
              dot_container.childNodes[i].classList.remove('active')
            }
            dot_container.childNodes[0].classList.add('active')
          }
        }
        sectionSliderEl.addEventListener('before.lory.init', handleProjectNavEvent)
        sectionSliderEl.addEventListener('after.lory.init', handleProjectNavEvent)
        sectionSliderEl.addEventListener('after.lory.slide', handleProjectNavEvent)
        sectionSliderEl.addEventListener('on.lory.resize', handleProjectNavEvent)

        let slider = lory(sectionSliderEl, {
          classNameFrame: 'section__projects-frame',
          classNameSlideContainer: 'section__projects',
          classNamePrevCtrl: 'section__prev',
          classNameNextCtrl: 'section__next',
          enableMouseEvents: true,
        })
      })
    }
  })
}

let initializeForm = () => {
  let inputs = [...document.querySelectorAll('label > input'), ...document.querySelectorAll('label > textarea')]
  Object.keys(inputs).forEach(k => {
    let input = inputs[k]
    // let label = input.parentNode
    // let span = label.querySelector('span')
    // input.addEventListener('change'
    // input.addEventListener('focus'
    // input.addEventListener('blur'
    let span = input.parentNode.querySelector('span')
    input.addEventListener('focus', e => {
      let input = e.target
      addClass('below', span)
    })
    input.addEventListener('blur', e => {
      let input = e.target
      if (input.value === '') {
        removeClass('below', span)
      }
    })
    input.addEventListener('change', e => {
      let input = e.target
      if (input.value === '') {
        removeClass('below', span)
      } else {
        addClass('below', span)
      }
    })
    if (input.value === '') {
      removeClass('below', span)
    } else {
      addClass('below', span)
    }
  })
}

let initializeDropdowns = () => {
  loadjs('/assets/scripts/dropkick.min.js', {
    success: () => {
      let selects = document.querySelectorAll('select:not([multiple])')
      Object.keys(selects).forEach(i => {
        let select = selects[i]
        let dk = new Dropkick(select, {mobile: true})
        let label = select.parentNode
        let span = label.querySelector('span')
        if (select.parentNode.tagName === 'LABEL') {
          label.addEventListener('click', e => {
            if (e.target === label || e.target === span) {
              if (dk.isOpen) {
                dk.close()
              } else {
                dk.open()
              }
            }
          })
          select.addEventListener('change', e => {
            if (select.value === '') {
              removeClass('below', span)
            } else {
              addClass('below', span)
            }
          })
        }
      })
    }
  })
}

let initializeFormSubmits = () => {
  let submits = [...document.querySelectorAll('[href^="#submit"]'), ...document.querySelectorAll('[type="submit"]')]
  Object.keys(submits).forEach(k => {
    let submit = submits[k]
    submit.addEventListener('click', e => {
      e.preventDefault()
      let form = closest('form', submit)
      e.target.setAttribute('disabled', '')
      if (form) {
        if (form.checkValidity()) {
          let ajax = form.attributes.ajax
          if (ajax) {
            var formData = new FormData()
            let elements = [...form.querySelectorAll('input'), ...form.querySelectorAll('textarea'), ...form.querySelectorAll('select')]
            for(var i=0; i<elements.length; i++)
            {
                formData.append(elements[i].name, elements[i].value)
            }
            let xmlHttp = new XMLHttpRequest()
            xmlHttp.onreadystatechange = function()
            {
              // console.log(xmlHttp.readyState, xmlHttp.status)
              if(xmlHttp.readyState == 4 && xmlHttp.status == 200)
              {
                if (xmlHttp.responseURL.match(/thank-you/)) {
                  addClass('success', form.querySelector('.loader'))
                  addClass('completed', form.closest('[class$="__wrapper"]'))
                } else {
                  addClass('error', form.querySelector('.loader'))
                  setTimeout(() => {
                    e.target.removeAttribute('disabled')
                    removeClass('error', form.querySelector('.loader'))
                  }, 5000)
                }
              }
            }
            let inputAction = (form.querySelector('input[name="action"]'))
            let action = (inputAction) ? '/' + inputAction.value : (form.getAttribute('action')) ? form.getAttribute('action') : ''
            let method = (form.getAttribute('method')) ? form.getAttribute('method') : 'POST'
            xmlHttp.open(method, action)
            xmlHttp.send(formData)
          } else {
            form.submit()
          }
        } else if (form.reportValidity) {
          e.target.removeAttribute('disabled')
          form.reportValidity()
        }
      }
    })
  })
}

let toggleDropdownCheckboxes = (e, el, parent, selected) => {
    if (hasClass('show', el)) {
        let selectedOptions = el.querySelectorAll('[type="checkbox"]:checked')
        let selectedText = []
        Object.keys(selectedOptions).forEach(k => {
            let selectedOption = selectedOptions[k]
            selectedText.push(selectedOption.value)
        })
        selected.innerHTML = selectedText.join(', ')
        if (parent.tagName === 'LABEL' && selectedOptions.length > 0) {
            let span = parent.querySelector('span')
            addClass('below', span)
        }
    } else {
        selected.innerHTML = ''
        if (parent.tagName === 'LABEL') {
            let span = parent.querySelector('span')
            removeClass('below', span)
        }
    }
    toggleClass('show', el)
}

let initializeDropdownCheckboxes = () => {
  let elements = document.querySelectorAll('.dropdown-checkboxes')
  document.addEventListener('click', e => {
    Object.keys(elements).forEach(i => {
      let el = elements[i]
      if (hasClass('show', el)) {
        let parent = el.parentNode
        let selected = el.querySelector('.dropdown-checkboxes__selected')
        if (!el.contains(e.target)) {
          toggleDropdownCheckboxes(e, el, parent, selected)
        }
      }
    })
  })
  Object.keys(elements).forEach(i => {
    let el = elements[i]
    let parent = el.parentNode
    let toggle = document.createElement('div')
    addClass('dropdown-checkboxes__toggle', toggle)
    let selected = document.createElement('div')
    addClass('dropdown-checkboxes__selected', selected)
    el.insertBefore(selected, el.firstChild)
    el.insertBefore(toggle, el.firstChild)
    let items = el.querySelectorAll('.dropdown-checkboxes__item')
    Object.keys(items).forEach(k => {
      let item = items[k]
      item.addEventListener('click', e => {
        e.stopPropagation()
        if (e.target === item) {
          let checkbox = item.querySelector('[type="checkbox"]')
          checkbox.checked = !checkbox.checked
        }
      })
    })
    el.parentNode.addEventListener('click', e => {
        toggleDropdownCheckboxes(e, el, parent, selected)
    })
  })
}

let initlizeSectionWaypoints = () => {
  loadjs([
    '/assets/scripts/noframework.waypoints.js',
    '/assets/scripts/inview.js'
  ], {
    async: false,
    success: () => {
      let sectionEls = document.querySelectorAll('.section')
      let sectionNavEls = document.querySelectorAll('.section-nav:not(.section-nav--sticky) .section-nav__wrapper a')
      let sectionStickyNavEls = document.querySelectorAll('.section-nav.section-nav--sticky .section-nav__wrapper a')
      let allSectionNavEls = [...sectionNavEls, ...sectionStickyNavEls]
      Object.keys(sectionEls).forEach(k => {
        let sectionEl = sectionEls[k]
        let inview = new Waypoint.Inview({
          element: sectionEl,
          exited: () => {
            removeClass('active', sectionNavEls[k])
            removeClass('active', sectionStickyNavEls[k])
            sectionNavEls[k].style.backgroundColor = ''
            sectionStickyNavEls[k].style.backgroundColor = ''
          }
        })
        let waypointDown = new Waypoint({
          element: sectionEl,
          offset: '50%',
          handler: dir => {
            if (dir === 'down') {
              Object.keys(allSectionNavEls).forEach(n => {
                removeClass('active', allSectionNavEls[n])
                allSectionNavEls[n].style.backgroundColor = ''
              })
              addClass('active', sectionNavEls[k])
              addClass('active', sectionStickyNavEls[k])
              sectionNavEls[k].style.backgroundColor = sectionEl.style.backgroundColor
              sectionStickyNavEls[k].style.backgroundColor = sectionEl.style.backgroundColor
            }
          }
        })
        let waypointUp = new Waypoint({
          element: sectionEl,
          handler: dir => {
            if (dir === 'up') {
              Object.keys(allSectionNavEls).forEach(n => {
                removeClass('active', allSectionNavEls[n])
                allSectionNavEls[n].style.backgroundColor = ''
              })
              addClass('active', sectionNavEls[k])
              addClass('active', sectionStickyNavEls[k])
              sectionNavEls[k].style.backgroundColor = sectionEl.style.backgroundColor
              sectionStickyNavEls[k].style.backgroundColor = sectionEl.style.backgroundColor
            }
          }
        })
      })
    }
  })
}

let runMatchHeights = () => {
  matchHeight('.projects--all .projectslisting .projectslisting__text')
  matchHeight('.locations__location .location__address')
  matchHeight('.locations__location .location__contact')
  matchHeight('.section__projects .project__wrapper .section__project')
}

let fitAllMarkers = mapId => {
  let markers = []
  smartMap.listMarkers().forEach(m => {
    let marker = smartMap.marker[m]
    if (marker.mapId === 'office-locations') {
      markers.push(marker)
    }
  })
  let bounds = new google.maps.LatLngBounds();
  for (let i = 0; i < markers.length; i++) {
   bounds.extend(markers[i].getPosition())
  }
  smartMap.map[mapId].fitBounds(bounds)
  smartMap.map[mapId].panToBounds(bounds)
  setTimeout(() => {
    if (smartMap.map[mapId].zoom > 18) {
      smartMap.map[mapId].setZoom(18)
    }
  }, 100)
}

loadjs('/assets/scripts/matchMedia.js')

ready( () => {
  // Stop placeholder links from affecting the url.
  let elements = [...document.querySelectorAll('[href="#"]'), ...document.querySelectorAll('[href^="#close"]'), ...document.querySelectorAll('[href^="#submit"]')]
  Array.prototype.forEach.call(elements, (el, i) => {
    el.addEventListener('click', e => {
      e.preventDefault()
    })
  })
  // Superscript IPSymbols
  intellectualPropertySymbols()
  // Add current browser to document classes
  document.documentElement.className += ' ' + navigator.sayswho.toLowerCase().replace(' ', '-')

  initializeHeaderNav()
  window.addEventListener('scroll', throttle(50, headerNavScroll))
  window.addEventListener('scroll', debounce(100, headerNavScroll))
  initializeMobileNav()
  if (document.querySelectorAll('#tray').length) {
    initializeTray()
    window.addEventListener('scroll', debounce(250, trayScroll))
  }
  if (document.querySelectorAll('.section-nav').length) {
    initializeSectionNav()
    window.addEventListener('scroll', throttle(50, sectionNavScroll))
  }
  initializeSearchLinks()
  if (document.querySelectorAll('.slider').length) {
    initializeSliders()
  }
  if (document.querySelectorAll('.slider-complex').length) {
    initializeComplexSliders()
  }
  if (document.querySelectorAll('.copy__slider').length) {
    initializeCopySliders()
  }
  if (document.querySelectorAll('.section__projects').length) {
    initializeSectionProjects()
  }
  if (document.querySelectorAll('label > input').length) {
    initializeForm()
  }
  // include dropkick dropdowns
  if (document.querySelectorAll('select').length > 0) {
    initializeDropdowns()
  }
  if (document.querySelectorAll('.dropdown-checkboxes').length > 0) {
    initializeDropdownCheckboxes()
  }
  if (document.querySelectorAll('[href^="#submit"]').length > 0) {
    initializeFormSubmits()
  }
  if (document.querySelectorAll('.section').length > 0) {
    initlizeSectionWaypoints()
  }
  if (document.querySelectorAll('.sidebar .sign-up').length > 0) {
    let signUp = document.querySelector('.sidebar .sign-up')
    signUp.addEventListener('click', e => {
      removeClass('hidden', signUp.parentNode.querySelector('.subscribe__wrapper'))
    })
  }
  if (document.querySelectorAll('.search-query a').length > 0) {
    document.querySelector('.search-query a').addEventListener('click', e => {
      let searchQuery = closest('.search-query', e.target)
      let searchInput = searchQuery.nextSibling.querySelector('input[name="query"]')
      addClass('hide', searchQuery)
      searchInput.focus()
      searchInput.selectionStart = searchInput.selectionEnd = searchInput.value.length
    })
  }
  let navsWithSubNav = document.querySelectorAll('.nav__wrapper > div:not(.utility) > div > a')
  Object.keys(navsWithSubNav).forEach(k => {
    let navItem = navsWithSubNav[k]
    navItem.addEventListener('click', e => {
      if (window.matchMedia('(max-width: 1023px)').matches) {
        e.preventDefault()
        let subNav = closest('.nav__wrapper > div', navItem).querySelector('.sub')
        addClass('show', subNav)
      }
    })
  })
  let subNavBacks = document.querySelectorAll('.nav__wrapper [href="#nav-back"]')
  Object.keys(subNavBacks).forEach(k => {
    if (window.matchMedia('(max-width: 1023px)').matches) {
      let navBack = subNavBacks[k]
      navBack.addEventListener('click', e => {
        e.preventDefault()
        let subNav = closest('.nav__wrapper > div', navBack).querySelector('.sub')
        removeClass('show', subNav)
      })
    }
  })
  runMatchHeights()
  setTimeout(runMatchHeights, 250)
  window.addEventListener('resize', debounce(250, runMatchHeights))
  if (typeof smartMap !== "undefined") {
    loadjs('/assets/scripts/mapstyles.js', {
      success: () => {
        smartMap.listMaps().forEach(mapId => {
          smartMap.map[mapId].setOptions({styles: window.mapStyleFull})
        })
      }
    })

    smartMap.listMarkers().forEach(markerId => {
      let marker = smartMap.marker[markerId]
      let mapId = marker.mapId

      marker.addListener('click', () => {
        smartMap.zoomOnMarker(mapId, markerId, 18)
      })
    })
    smartMap.listInfoWindows().forEach(infoWindowId => {
      let infoWindow = smartMap.infoWindow[infoWindowId]

      infoWindow.addListener('closeclick', () => {
        let mapId = infoWindowId.split('.').shift()
        fitAllMarkers(mapId)
      })
    })
    smartMap.listMaps().forEach(mapId => {
      fitAllMarkers(mapId)
      smartMap.map[mapId].addListener('zoom_changed', () => {
        loadjs('/assets/scripts/mapstyles.js', {
          success: () => {
            if (smartMap.map[mapId].zoom >= 6) {
              smartMap.map[mapId].setOptions({styles: window.mapStyle})
            } else {
              smartMap.map[mapId].setOptions({styles: window.mapStyleFull})
            }
          }
        })
      })
    })
  }
})

// Inlcude Video.js on pages that use video-js
if (document.getElementsByClassName('video-js').length > 0) {
  loadjs([
    '/assets/scripts/video.js',
    '/assets/vendor/video-js/video-js.css',
    '/assets/styles/video.css',
  ])
}

if (document.querySelectorAll('[map-api-key]').length > 0) {
  let mapApiKey = document.querySelector('[map-api-key]').getAttribute('map-api-key')
  loadjs('//maps.google.com/maps/api/js?key=' + mapApiKey, 'googlemaps')
}

loadjs([
    '/assets/scripts/lazysizes.js',
    '/assets/scripts/lazysizes-plugins/ls.bgset.js',
],
{
    async: false,
    success: () => {
      document.addEventListener('lazybeforeunveil', e => {
        let bg = e.target.getAttribute('data-bg')
        if (bg) {
          e.target.style.backgroundImage = 'url(' + bg + ')'
        }
        if (e.target.getAttribute('map-api-key')) {
          loadjs.ready('googlemaps',
          {
            success: () => {
            }
          })
        }
      })
    }
})
